<template>
  <div style="width: 100%; text-align: center">
    <img src="@/assets/imgs/phone-nodata.png" alt="" class="phone-nodata" />
    <div class="phone-nodata-txt">抱歉，未找到符合条件的商品。</div>
  </div>
</template>

<script>
export default {
  name: 'NoDataTips',
}
</script>

<style lang="scss" scoped>
.phone-nodata {
  width: 500px;
  margin: 0 auto;
  margin-top: 50px;
}
.phone-nodata-txt {
  text-align: center;
  color: #aaa;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .phone-nodata {
    width: 90%;
    padding: 10px;
    padding-top: 50px;
  }
  .phone-nodata-txt {
    text-align: center;
    color: #aaa;
  }
}
</style>
