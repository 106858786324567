<template>
  <!---搜索栏组件-->
  <div class="searchbar-box" :style="{ 'background-color': searchBoxColor }">
    <div class="search-box">
      <el-input
        v-model.trim="searchWord"
        maxlength="20"
        :placeholder="placeholder"
        clearable
        @keyup.enter.native="handleClick"
        type='text'
      ></el-input>
      <div class="search-icon-box" @click="handleClick" :disabled="isSearching">
        <img src="@/assets/imgs/search-icon.png" alt="" class="search-icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    // 搜索框背景色
    searchBoxColor: {
      type: String,
      // default: "#FFF"
    },
    placeholder: {
      type: String,
      default: "请输入搜索关键字"
    }
  },
  data() {
    return {
      searchWord: "",
      isSearching: false
    };
  },
  methods: {
    handleClick() {
      this.$emit("handleSearch", this.searchWord);
    },
  }
};
</script>

<style lang="scss" scoped>
.searchbar-box {
  width: 1140px;
  margin: 0 auto;
  padding: 40px 20px 0;
  .search-box {
    display: flex;
    width: 100%;
    height: 100%;
    input {
      width: 50%;
      height: 40px;
      margin-left: 25%;
      border: 1px solid #409eff;
      box-sizing: border-box;
      border-radius: 10px;
      padding-left: 20px;
      outline: none;
    }
    .search-icon-box {
      height: 40px;
      width: 70px;
      cursor: pointer;
      text-align: center;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #409eff;
    }
    .search-icon {
      width: 30px;
      height: 30px;
      margin: 5px auto;
    }
  }
}
@media screen and (max-width: 768px) {
  .searchbar-box {
    // position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    // background-color: #fff;
    z-index: 9;
    padding: 10px 20px;
    // border-top: 1px solid #eee;
    // border-bottom: 1px solid #eee;
    box-sizing: border-box;
    .search-box {
      width: 100%;
      input {
        width: 90%;
        margin-left: 5%;
      }
    }
  }
}
</style>
<style>
.searchbar-box .el-input {
  width: auto;
  margin-left: 270px;
}
.searchbar-box .el-input__inner {
  width: 500px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media screen and (min-width: 320px) and (max-width: 550px) {
  .searchbar-box .el-input {
    width: auto;
    margin-left: 0;
  }
  .searchbar-box .el-input__inner {
    display: inline-block;
    width: 255px !important;
    background-color: transparent;
  }
}
</style>
