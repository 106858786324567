<template>
  <div class="goods-box">
    <div class="goods-tit page-item-tit">热卖商品</div>
    <SearchBar @handleSearch="handleSearch" />
    <div v-if="allAppliance.length">
      <div class="goods-content-box" v-if="mobile === true">
        <div
          class="goods-item-box"
          v-for="item in allAppliance.slice(0, loadedDataCount)"
          :key="item.id"
          @click="goGoodDetail(item.policyId)"
        >
          <div class="goods-img">
            <img :src="item.imgList[0] || defaultImg" alt="" />
          </div>
          <div class="goods-name page-content-tit">{{ item.goodsName }}</div>
          <div class="goods-intr">{{ item.goodsIntroduction }}</div>
          <div class="goods-payPrice">¥{{ item.payPrice }}起</div>
        </div>
      </div>
      <div class="goods-content-box" v-else>
        <div
          class="goods-item-box"
          v-for="item in allAppliance"
          :key="item.id"
          @click="goGoodDetail(item.policyId)"
        >
          <div class="goods-img">
            <img :src="item.imgList[0] || defaultImg" alt="" />
          </div>
          <div class="goods-name page-content-tit">{{ item.goodsName }}</div>
          <div class="goods-intr">{{ item.goodsIntroduction }}</div>
          <div class="goods-payPrice">¥{{ item.payPrice }}起</div>
        </div>
      </div>
    </div>
    <div class="new-list" v-else>
      <NoDataTips />
    </div>
  </div>
</template>
<script>
import 'swiper/css/swiper.min.css'
import SearchBar from '@/components/SearchBar'
import NoDataTips from '@/components/NoDataTips'
import { getGoodList } from '@/api/home'
import { validateGoodStatus } from '@/utils'
import { getwxShare } from '@/utils/wexinShare'
export default {
  components: {
    // GoodsList
    SearchBar,
    NoDataTips,
  },
  data() {
    return {
      bannerHeight: '',
      config: null,
      isLoading: true, //控制加载层
      defaultImg: require('@/assets/imgs/no-image.png'),
      mobile: false,
      allAppliance: [], // 假设商品数据从接口获取并填充到此数组中
      loadedDataCount: 0,
    }
  },
  mounted() {
    if (this.$device.mobile === true) {
      this.mobile = true
    } else {
      this.mobile = false
    }
    this.getGoodsList()
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动事件
    this.initWeChatConfig()
  },
  methods: {
    async initWeChatConfig() {
      var url = window.location.href.split('#')[0].split('?')[0];
      // const parsedURL = new URL(url);
      // const domain = parsedURL.origin;
      var goodsType = 3
      var params={
        url:url,
        goodsId:3,
        goodsType:goodsType
      }
    const path ='#/goodsAll';
     getwxShare(params,url,goodsType,path,'热卖商品列表')
    },
    handleSearch(val) {
      this.getGoodsList(val)
    },
    loadMoreData() {
      // 判断剩下的数据是否足够加载8条
      if (this.allAppliance.length - this.loadedDataCount < 5) {
        // 剩下的数据不足5条，直接展示剩下的数据
        this.loadedDataCount = this.allAppliance.length
      } else {
        // 加载更多数据
        this.loadedDataCount += 8
      }
    },
    gotoGoods(item) {
      if (item.gotoUrl) {
        this.$router.push({
          path: '/goods/details',
          query: { productID: item.gotoUrl },
        })
      }
    },
    // 获取商品列表
    async getGoodsList(name) {
      var goodsName = name ? name : ''
      const res = await getGoodList(goodsName)
      if (res.code == 200) {
        this.allAppliance = res.data.ruGoodsList || []
        if (this.mobile === true) {
          this.loadMoreData()
        }
        this.isLoading = false
      }
    },
    // 跳转商品详情
    async goGoodDetail(productID) {
      // console.log(type);
      const isGrounding = await validateGoodStatus(productID)
      if (isGrounding) {
        this.notifyError('当前商品已下架！')
        return
      }
      this.$router.push({
        path: '/goods/details',
        query: { productID },
      })
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      if (
        scrollTop + windowHeight >= scrollHeight - 100 &&
        !this.isLoading &&
        !this.isError
      ) {
        // 判断是否滚动到页面底部
        this.loadMoreData()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.new-list {
  display: flex;
  flex-wrap: wrap;
  .new-item {
    cursor: pointer;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    padding: 30px 0;
    transition: all 0.3s;
    .new-img {
      width: 32%;
      height: 175px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .new-info {
      margin-left: 20px;
      width: 90%;
    }
    .new-info-txt {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
    }
    .new-tit {
      font-weight: bold;
      margin-bottom: 10px;
      width: 70%;
    }
    .new-date {
      color: rgb(153, 153, 153);
    }
  }
  .new-item:hover {
    padding: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* 鼠标悬浮时添加阴影效果 */
  }
  .new-item:nth-last-of-type(1) {
    border-bottom: none;
  }
}
.goods-box {
  width: $type-area-width;
  margin: 0 auto;
  padding: 40px 0;
  min-height: 63vh;
  .seckill-tit {
    display: flex;
    justify-content: space-between;
    .seckill-all {
      // align-items: flex-end;
      // flex-basis: calc(20%);
      display: none;
      font-size: 15px;
      line-height: 24px;
    }
  }

  .pagination-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* 可以根据需要添加其他样式属性 */
  }

  .pagination-container {
    margin-top: 10px;
  }
  .goods-content-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 30px;
    .goods-item-box {
      cursor: pointer;
      width: 18.5%;
      padding: 0px 0 1.5625rem;
      margin: 8px;
      /* margin-right: 1.04167rem; */
      margin-bottom: 1.04167rem;
      background-color: #fff;
      border-radius: 0.3125rem;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .seckill-statistic-time {
        height: 40px;
        background-color: #fa8919;
        opacity: 0.8;
        text-align: center;
      }
      .goods-name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 18px;
        font-weight: 400;
        color: #333;
        text-align: center;
        text-overflow: ellipsis;
        // white-space: nowrap;
        overflow: hidden;
        margin: 25px 10px 0;
      }
      .goods-img {
        display: block;
        /* max-width: 100%; */
        /* max-height: 100%; */
        width: 100%;
        height: 195px;
        margin: 0 auto;
        border-radius: 0.3125rem 0.3125rem 0 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .goods-intr {
        white-space: normal; /* 多行显示 */
        display: -webkit-box;
        margin: 10px 20px;
        font-size: 12px;
        font-weight: 400;
        color: #fba147;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
      }
      .goods-payPrice {
        display: -webkit-box;
        margin: 10px 20px;
        font-size: 18px;
        font-weight: 600;
        color: #fa8919;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
      }
      .discount-box {
        // display: flex;
        // justify-content: center;
        // margin-top: 15px;
      }
      .goods-discount-val {
        color: #d50f1c;
        font-weight: bold;
        font-size: 20px;
        margin-left: 5px;
      }
      .seckillModule {
        div {
          // margin-right: 5px;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .preferential {
          font-size: 0.625rem;
          /* width: 100%; */
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
        }
        .goods-discount-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;
          .seckillTit {
            border: 1px solid #fa8919;
            background: #fa8919;
            color: white;
            padding: 3px;
            // width: 49px;
            text-align: center;
            border-radius: 11px;
            font-size: 10px;
            // padding: 2px;
          }
        }
      }
      .goods-discount-originalPrice {
        margin-left: 10px;
        font-size: 8px;
        display: flex; /* 将容器设置为flex布局 */
        align-items: center; /* 垂直居中对齐 */
        color: #b0b0b0;
      }
      .goods-discount-discount {
        font-size: 12px;

        /* 可选样式 */
        // color: #b0b0b0;
      }
      .gobuy-btn {
        display: flex;
        flex-direction: column;
        background-color: #d50f1c;
        padding: 8px 15px;
        border-radius: 6px;
        color: #fff;
        span {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .goods-item-box:hover {
      z-index: 2;
      -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
    }
  }
}
.goods-box {
  // min-height: 50vh;
}

@media screen and (min-width: 320px) and (max-width: 750px) {
  .new-list {
    padding-bottom: 30px;
  }
  .goods-box {
    width: 95%;
    padding: 0;
    margin-top: 15px;
    background-color: initial;
    .seckill-all {
      display: inline-block !important;
    }
    .goods-item-box {
      width: 48% !important;
      padding: 0 !important;
      padding-bottom: 30px !important;
      margin: 0 !important;
      background-color: #fff !important;
      margin-bottom: 15px !important;
    }
    .goods-content-box {
      justify-content: space-between;
      margin-top: 10px;
    }
  }
}
</style>
